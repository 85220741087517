import { storeToRefs } from 'pinia'
import { GAME_PAGE_URL } from '~/constants/lobby'
import {
  IMenuHeader,
  IMenuUserDesktop,
  ISubMenuHeader,
  IMenuWithDraw,
  IMenuDepositBank,
  IMenuDepositDesktop,
  IMenuDeposit,
  IMenuMobile,
  IMenuBottom
} from '~/types/menu.type'
import { ACCOUNT_URLS, LOBBY_CASINO_URLS, LOBBY_GAME_URLS, PAGE_URLS, PROMOTION_URLS } from '~/config/page-url'
import { LABEL } from '~/constants/account/history/transactions'
import { useAppStore } from '@/store/app'
import { useGameStore } from '~~/store/game'
import { useGame } from '~~/composables/game/useGame'
import { IContact } from '~~/types/seo'
import { MODAL_QUERY } from '~/config/constant'

export const PATH_ICON = '/assets/images/components/desktop/menu/'

export const TRANSACTION_P2P_TYPE = {
  BUY: 'buy',
  SELL: 'sell'
}
export const PROMOTION_SUBMENU: ISubMenuHeader[] = [
  {
    id: 1,
    name: 'promotion',
    display_name: 'Thưởng thành viên mới',
    url: PROMOTION_URLS.thanhvienmoi,
    activeUrl: [PROMOTION_URLS.thanhvienmoi],
    alias: PROMOTION_URLS.thanhvienmoi,
    icon: {
      inactive: PATH_ICON + 'promotion/thanhvienmoi.webp',
      active: PATH_ICON + 'promotion/thanhvienmoi.webp'
    },
    type: 'thanhvienmoi',
    disable: false
  },
  {
    id: 2,
    name: 'promotion',
    display_name: 'Chương trình VIP hấp dẫn',
    url: PROMOTION_URLS.vip,
    activeUrl: [PROMOTION_URLS.vip],
    alias: PROMOTION_URLS.vip,
    icon: {
      inactive: PATH_ICON + 'promotion/km-vip.webp',
      active: PATH_ICON + 'promotion/km-vip.webp'
    },
    type: 'km-vip',
    disable: false,
    isNotShowLine: true
  },
  {
    id: 3,
    name: 'promotion',
    display_name: 'Hoàn trả cao mỗi ngày',
    url: PROMOTION_URLS.refund,
    activeUrl: [PROMOTION_URLS.refund],
    alias: PROMOTION_URLS.refund,
    icon: {
      inactive: PATH_ICON + 'promotion/hoantracao.webp',
      active: PATH_ICON + 'promotion/hoantracao.webp'
    },
    type: 'hoantracao',
    disable: false,
    isNotShowLine: true
  },
  {
    id: 4,
    name: 'promotion',
    display_name: 'Thứ 7 tặng 100k',
    url: PROMOTION_URLS.tang100k,
    activeUrl: [PROMOTION_URLS.tang100k],
    alias: PROMOTION_URLS.tang100k,
    icon: {
      inactive: PATH_ICON + 'promotion/tang100k.webp',
      active: PATH_ICON + 'promotion/tang100k.webp'
    },
    type: 'tang100k',
    disable: false,
    isNotShowLine: true
  }
]
export const MENUS_DEFAULT: IMenuHeader[] = [
  {
    type: 'sports',
    name: 'the-thao',
    tab: 'tab-sport',
    title: 'Thể thao',
    url: PAGE_URLS.SPORT,
    activeUrl: PAGE_URLS.SPORT
  },
  {
    type: 'games',
    name: 'game-type',
    title: 'Cổng Games',
    tab: 'tab-games',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    activeUrl: LOBBY_GAME_URLS.TABLE_GAME,
    subMenu: []
  },
  {
    type: 'games',
    name: 'quay-so',
    title: 'Quay Số',
    tab: 'tab-games',
    url: LOBBY_GAME_URLS.QUAY_SO,
    activeUrl: LOBBY_GAME_URLS.QUAY_SO
  },
  {
    type: 'livecasino',
    name: 'casino',
    title: 'Live Casino',
    tab: 'tab-casino',
    url: LOBBY_CASINO_URLS.ROOT,
    activeUrl: LOBBY_CASINO_URLS.ROOT,
    subMenu: []
  },
  {
    name: 'line',
    title: '',
    tab: '',
    activeUrl: '',
    subMenu: undefined
  },
  {
    name: 'khuyen-mai',
    title: 'Khuyến mãi',
    tab: 'tab-promotion',
    url: PAGE_URLS.PROMOTION,
    activeUrl: PAGE_URLS.PROMOTION,
    subMenu: []
  }
]
export const getMenus = () => {
  const { $config, $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  const {
    fetchCongGame
  } = useGame()
  if (Object.keys(providerGames.value).length === 0) {
    fetchCongGame()
  }
  const urlGameLobby = computed(() => {
    if (providerGames.value && Array.isArray(providerGames.value) && providerGames.value.length > 0) {
      return `${PAGE_URLS.GAME}/${providerGames.value?.[0].alias}`
    }
    return `${PAGE_URLS.GAME}/table-games`
  })
  return {
    MENUS: [
      {
        type: 'sports',
        name: 'the-thao',
        tab: 'tab-sport',
        title: 'Thể thao',
        url: PAGE_URLS.SPORT,
        activeUrl: PAGE_URLS.SPORT
      },
      {
        type: 'games',
        name: 'game-type',
        title: 'Cổng Games',
        tab: 'tab-games',
        url: urlGameLobby,
        activeUrl: LOBBY_GAME_URLS.TABLE_GAME,
        subMenu: []
      },
      {
        type: 'games',
        name: 'quay-so',
        title: 'Quay Số',
        tab: 'tab-games',
        url: LOBBY_GAME_URLS.QUAY_SO,
        activeUrl: LOBBY_GAME_URLS.QUAY_SO
      },
      {
        type: 'livecasino',
        name: 'casino',
        title: 'Live Casino',
        tab: 'tab-casino',
        url: LOBBY_CASINO_URLS.ROOT,
        activeUrl: LOBBY_CASINO_URLS.ROOT,
        subMenu: []
      },
      {
        name: 'line',
        title: '',
        tab: '',
        activeUrl: '',
        subMenu: undefined
      },
      {
        name: 'khuyen-mai',
        title: 'Khuyến mãi',
        tab: 'tab-promotion',
        url: PAGE_URLS.PROMOTION,
        activeUrl: PAGE_URLS.PROMOTION,
        subMenu: PROMOTION_SUBMENU
      }
    ]
  }
}

export const TYPE_BET_MB = [
  {
    name: 'sport',
    title: 'Thể Thao',
    url: PAGE_URLS.SPORT,
    image: 'assets/images/home/home-sport-mb.webp',
    tag: PATH_ICON + 'tag-menu-hot-new.svg'
  },
  {
    name: 'Table games',
    title: 'Table Games',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    image: 'assets/images/home/home-table-game-mb.webp',
    jackpot: 'jackpotIngame'
  },
  {
    name: 'Games bài',
    title: 'Game Bài',
    url: LOBBY_GAME_URLS.GAME_BAI,
    image: 'assets/images/home/home-game-bai-mb.webp'
  },
  {
    name: 'Quay số',
    title: 'Quay Số',
    url: LOBBY_GAME_URLS.QUAY_SO,
    image: 'assets/images/home/home-quay-so-mb.webp',
    tag: PATH_ICON + 'tag-menu-event-new.svg'
  },
  {
    name: 'Bắn cá',
    title: 'Bắn Cá',
    url: LOBBY_GAME_URLS.BAN_CA,
    image: 'assets/images/home/home-ban-ca-mb.webp',
    jackpot: 'jackpotFishing'
  },
  {
    name: 'Lô đề',
    title: 'Lô Đề',
    url: LOBBY_GAME_URLS.LO_DE,
    image: 'assets/images/home/home-lode-mb.webp'
  },
  {
    name: 'Nổ hũ',
    title: 'Nổ Hũ/Slots',
    url: `${LOBBY_GAME_URLS.SLOTS_GAME}`,
    image: 'assets/images/home/home-slots-mb.webp',
    jackpot: 'jackpotNohu'
  },
  {
    name: 'live_casino',
    title: 'Live Casino',
    image: 'assets/images/home/home-casino-mb.webp',
    url: GAME_PAGE_URL.all + '/all'
  },
  {
    name: 'Games nhanh',
    title: 'Games Nhanh',
    url: LOBBY_GAME_URLS.QUICK_GAMES,
    image: 'assets/images/home/home-game-nhanh-mb.webp'
  }
]

export const USER_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Tài khoản',
    menuUserName: 'Thông tin cá nhân',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-icon-profile',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-profile'
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: 'icon-deposit2',
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-deposit2'
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-withdraw2',
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-withdraw2'
  },
  {
    id: 4,
    name: 'Mua bán P2P',
    url: ACCOUNT_URLS.P2P,
    icon: 'icon-icon-p2p',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-p2p'
  },
  {
    id: 5,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history'
  },
  {
    id: 6,
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.BONUS,
    icon: 'icon-icon-gift',
    activeUrl: ACCOUNT_URLS.BONUS,
    activeUrlRelative: ACCOUNT_URLS.BONUS,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-gift'
  },
  {
    id: 7,
    name: 'Ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-icon-bank',
    activeUrl: ACCOUNT_URLS.BANK,
    activeUrlRelative: ACCOUNT_URLS.BANK,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-bank'
  },
  {
    id: 8,
    name: 'Đăng xuất',
    url: '',
    icon: 'icon-icon-logout',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const MENU_WITHDRAW: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank-bold',
    key: 'bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Mua bán P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'sell',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`,
    icon: 'icon-p2pc',
    iconMB: 'icon-p2p-bold',
    key: 'p2p',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto-menu',
    key: 'crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    key: 'cardList',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_WITHDRAW_DESKTOP: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-icon-bank',
    key: 'bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    key: 'crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    key: 'cardList',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_DEPOSIT: IMenuDeposit[] = [
  {
    name: 'CodePay',
    nameMB: 'Codepay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    key: 'nicepayBanks',
    desc: 'Khuyến mãi hấp dẫn',
    icon: 'icon-codepay',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    nameMB: 'Codepay 2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-codepay2',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Nạp P2P',
    nameMB: 'Mua bán P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'buy',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    key: 'buy',
    icon: 'icon-p2p-bold',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    nameMB: 'Tiền ảo',
    desc: 'Nạp USDT tức thì',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    icon: 'icon-crypto-menu',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: true,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    nameMB: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: ACCOUNT_URLS.DEPOSIT_MOMO,
    key: 'momos',
    desc: 'Momo, Viettel Money',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    nameMB: 'Thẻ cào',
    desc: 'Nhiều nhà mạng hỗ trợ',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.DEPOSIT_CARD,
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_DESKTOP: IMenuDepositDesktop[] = [
  {
    name: 'Ngân Hàng',
    queryValue: 'codepay',
    fullPath: [ACCOUNT_URLS.DEPOSIT_BANK, ACCOUNT_URLS.DEPOSIT_DA, ACCOUNT_URLS.DEPOSIT_CODEPAY2],
    key: 'nicepayBanks',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-bank',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    component2: null,
    componentMobile: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền Ảo',
    desc: 'Nạp USDT tức thì',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CRYPTO],
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/crypto/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Ví Điện Tử',
    queryValue: 'e-wallet',
    fullPath: [ACCOUNT_URLS.DEPOSIT_MOMO, ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY, ACCOUNT_URLS.DEPOSIT_ZALO_PAY],
    key: 'e-wallet',
    desc: 'Siêu tiện lợi, nhanh chóng',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/e-wallet/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ Cào',
    desc: 'Mệnh giá đa dạng',
    queryValue: 'card',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CARD],
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/card/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]
export const MENU_DEPOSIT_BANK: IMenuDepositBank[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    pathActive: ACCOUNT_URLS.DEPOSIT_BANK,
    key: 'nicepayBanks',
    icon: 'icon-codepay',
    iconActive: 'icon-codepay',
    isHot: true,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    icon: 'icon-codepay2',
    iconActive: 'icon-codepay2',
    isHot: false,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  }
]

export const MENU_HISTORY = [
  {
    name: LABEL.HISTORY_TRANSACTIONS,
    link: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history-transaction',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/transactions/index.vue')),
    componentMobile: defineAsyncComponent(
      () => import('~/components/mobile/pages/account/history/transactions/index.vue')
    )
  },
  {
    name: LABEL.HISTORY_BETS,
    link: ACCOUNT_URLS.BET_HISTORY,
    icon: 'icon-history-bets',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/bets/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/bet/index.vue'))
  }
]

export const NOTE = [
  'Nạp / Rút bằng tài khoản chính chủ.',
  'Hỗ trợ chuyển tiền liên ngân hàng.',
  'Lưu lại biên lai để đối chiếu khi cần.'
]

export const USER_MENU_MOBILE: IMenuMobile[] = [
  {
    name: 'Quản lý tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-icon-profile',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Quản lý ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-icon-bank',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Mua bán P2P',
    url: ACCOUNT_URLS.P2P,
    icon: 'icon-icon-p2p',
    activeUrl: [ACCOUNT_URLS.P2P],
    isLiveChat: false
  },
  {
    name: 'Lịch sử giao dịch',
    url: `${ACCOUNT_URLS.TRANSACTION_HISTORY}`,
    icon: 'icon-icon-history-transaction',
    activeUrl: [ACCOUNT_URLS.TRANSACTION_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Lịch sử cá cược',
    url: `${ACCOUNT_URLS.BET_HISTORY}`,
    icon: 'icon-icon-history-bets',
    activeUrl: [ACCOUNT_URLS.BET_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.BONUS,
    icon: 'icon-icon-gift',
    activeUrl: [ACCOUNT_URLS.BONUS],
    isLiveChat: false
  },
  {
    name: 'Live chat 24/7',
    url: '',
    icon: 'icon-icon-livechat',
    activeUrl: [],
    isLiveChat: true
  }
]
export const getBottomMenu = () => {
  const { $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  const {
    fetchCongGame
  } = useGame()

  const { data: gameData } = useAsyncData(() => {
    const promises = []
    if (providerGames.value?.length === 0) {
      promises.push(fetchCongGame())
    }
    return Promise.allSettled(promises)
  })

  const firstAlias = computed(() => {
    if (providerGames.value && Array.isArray(providerGames.value) && providerGames.value.length > 0) {
      return providerGames.value?.[0].alias
    }
    return 'table-games'
  })
  return {
    BOTTOM_MENU: [
      {
        key: 'promotion',
        name: 'Khuyến mãi',
        url: PAGE_URLS.PROMOTION,
        activeUrl: PAGE_URLS.PROMOTION,
        icon: PATH_ICON + 'icon-gift.png'
      },
      {
        key: 'home',
        name: 'Trang chủ',
        url: PAGE_URLS.HOME,
        activeUrl: PAGE_URLS.HOME,
        icon: PATH_ICON + 'icon-home.png'
      },
      {
        name: 'Tìm kiếm',
        url: MODAL_QUERY.SEARCH,
        activeUrl: MODAL_QUERY.SEARCH,
        icon: PATH_ICON + 'icon-home-search.png'
      },
      {
        type: 'game-type',
        name: 'Cổng Games',
        url: `${LOBBY_GAME_URLS.GAME}/${firstAlias.value}`,
        activeUrl: `${LOBBY_GAME_URLS.GAME}/${firstAlias.value}`,
        icon: PATH_ICON + 'icon-games.png'
      },
      {
        key: 'sport',
        name: 'Thể thao',
        url: PAGE_URLS.SPORT,
        activeUrl: PAGE_URLS.SPORT,
        icon: PATH_ICON + 'icon-the-thao.png'
      },
      {
        key: 'live-casino',
        name: 'Live casino',
        url: LOBBY_CASINO_URLS.ROOT,
        activeUrl: LOBBY_CASINO_URLS.ROOT,
        icon: PATH_ICON + 'icon-livecasino.png'
      }
    ]
  }
}

export const BOTTOM_MENU: IMenuBottom[] = [
  {
    key: 'promotion',
    name: 'Khuyến mãi',
    url: PAGE_URLS.PROMOTION,
    activeUrl: PAGE_URLS.PROMOTION,
    icon: PATH_ICON + 'icon-gift.png'
  },
  {
    key: 'home',
    name: 'Trang chủ',
    url: PAGE_URLS.HOME,
    activeUrl: PAGE_URLS.HOME,
    icon: PATH_ICON + 'icon-home.png'
  },
  {
    key: 'sport',
    name: 'Thể thao',
    url: PAGE_URLS.SPORT,
    activeUrl: PAGE_URLS.SPORT,
    icon: PATH_ICON + 'icon-the-thao.png'
  },
  {
    key: 'games',
    name: 'Cổng Games',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    activeUrl: LOBBY_GAME_URLS.GAME,
    icon: PATH_ICON + 'icon-games.png'
  },
  {
    key: 'live-casino',
    name: 'Live casino',
    url: LOBBY_CASINO_URLS.ROOT,
    activeUrl: LOBBY_CASINO_URLS.ROOT,
    icon: PATH_ICON + 'icon-livecasino.png'
  },
  {
    key: 'lottery',
    name: 'Quay số',
    url: LOBBY_GAME_URLS.QUAY_SO,
    activeUrl: LOBBY_GAME_URLS.QUAY_SO,
    icon: PATH_ICON + 'icon-lottery-2.png'
  }
]
